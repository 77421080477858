<template>
  <a-card :border="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
        <a-row :gutter="8">
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="折扣名称">
              <a-input v-model="searchData.rebateName" placeholder="折扣名称" allowClear></a-input>
            </a-form-model-item>
          </a-col>
<!--          <a-col :md="8" :xl="6" :xxl="6" :sm="12">-->
<!--            <a-form-model-item label="大区事务所">-->
<!--              <a-cascader-->
<!--                :options="dealerList"-->
<!--                v-model="regionalOffice"-->
<!--                change-on-select-->
<!--                placeholder="请选择大区/事务所"-->
<!--                @change="onChange"-->
<!--                :field-names="{ label: 'title', value: 'id', children: 'children' }"-->
<!--              />-->
<!--            </a-form-model-item>-->
<!--          </a-col>-->
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="经销商名称">
              <a-input v-model="searchData.dealerName" placeholder="经销商名称" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-model-item label="折扣时间">
              <DateRange
                :startTime.sync="searchData.startTimeString"
                :endTime.sync="searchData.endTimeString"
              ></DateRange>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="6" :xl="5" :md="8" sm="2">
            <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
<!--        <a-button v-if="isAdd" type="primary" @click="toHandler('add')">新增</a-button>-->
        <!--            <a-button type="primary" @click="toHandler('edit')">修改</a-button>-->
        <a-button type="primary" @click="toHandler('checkDetail')" icon="copy">使用记录</a-button>
<!--        <a-button v-if="isDelete" type="primary" @click="toHandler('del')">删除</a-button>-->
<!--        <downLoad v-if="isExport" method="get" api="/api/dealer/dealer/dealerRebate/excelExportRebate" :params="searchData" name="折扣金额.xls">导出</downLoad>-->
      </div>
      <!-- 数据表格 -->
      <a-table
        :loading="tableLoading"
        :row-selection="rowSelection"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="tableData"
        bordered
        :pagination="page"
        @change="changeTable"
        :customRow="changeTableRow"
        :rowClassName="rowClassName"
        :scroll="{ x: 1600 }"
      >
        <span slot="flagEnable" slot-scope="text, record">
          <a-switch v-model="record.flagEnable" @change="onChangeStatus(record)" />
        </span>
        <span slot="startTime" slot-scope="text, row">
          <span>{{ row.startTime }} ~ {{ row.endTime }}</span>
        </span>
        <span slot="status" slot-scope="text">
          <a-tag v-if="text == 0" color="#909399">已过期</a-tag>
          <a-tag v-if="text == 1" color="#67c23a">正常</a-tag>
          <a-tag v-if="text == 2" color="#108ee9">未到使用时间</a-tag>
          <a-tag v-if="text == 3" color="#ff7875">即将过期</a-tag>
        </span>
        <span slot="rebateFilesNum" slot-scope="text, record">
          <span v-if="text <= 0">未上传附件</span>
          <a-button v-if="text > 0" style="width: 80px" icon="eye" @click.stop="getFileList(record)">查看</a-button>
        </span>
        <span slot="rebateAmount" slot-scope="text"> {{text | formatMoney}} </span>
        <span slot="remainingAmount" slot-scope="text"> {{text | formatMoney}} </span>
      </a-table>
      <!-- 编辑表单 -->
      <DealerRebate-edit-modal ref="DealerRebateEditModal" @reload="getData"></DealerRebate-edit-modal>

      <UsedDetailed ref="UsedDetailed"> </UsedDetailed>
      <RebateFileListModal ref="RebateFileListModal"></RebateFileListModal>
    </div>
  </a-card>
</template>

<script>
import { columns } from './components/colums.js'
import DealerRebateEditModal from './components/DealerRebateEditModal.vue'
import UsedDetailed from '@/views/dealer_rebate/UsedDetailed'
import { delDealerRebate, listDealerRebate, selectByIdDealerRebate } from './api/DealerRebateApi'
import {checkPermission} from "@/utils/permissions";
import RebateFileListModal from './components/RebateFileListModal.vue'

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'dealerRebate',
  components: {
    DealerRebateEditModal,
    UsedDetailed,
    RebateFileListModal
  },
  data() {
    return {
      columns: columns,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      isAdd: checkPermission('dealer:rebate:add'),
      isDelete: checkPermission('dealer:rebate:delete'),
      isExport: checkPermission('dealer:rebate:export'),
      selectedRowKeys: [],
      selectedRows: [],
      dealerList: [],
      regionalOffice: [],
      distributor_name: this.$route.params.dealerName,
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
  },
  mounted() {
    if (this.distributor_name) {
      this.searchData.dealerName = this.distributor_name
    }
    this.getData()
  },
  methods: {
    getFileList(row) {
      this.$refs.RebateFileListModal.isShow(row)
    },
    // 隔行换色
    rowClassName(record, index) {
      let className = 'light-row'
      if (index % 2 === 1) className = 'dark-row'
      return className
    },
    /**
     * 获取表格数据
     */
    getData() {
      this.tableLoading = true
      listDealerRebate({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))

      this.axios.post('/api/firm/mhiac/mhiacFirm/getTree').then((res) => {
        // 获取大区事务所经销商数据
        this.dealerList = res.body
      })
    },

    onChange(val) {
      this.searchData.areaId = val[0]
      this.searchData.firmId = val[1]
    },

    /**
     * 筛选查询
     */
    getDataFilter() {
      this.page.current = 1;
      this.getData()
    },

    // 修改状态
    onChangeStatus(row) {
      this.axios.post('/api/dealer/dealer/dealerRebate/status/' + row.id).then(res => {
        if(res.code) {
          this.$message.success(res.message)
        }
      }).catch(err => {
      })
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.page.current = 1;
      this.page.pageSize = 10
      // this.page = Object.assign({}, this.page)
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.DealerRebateEditModal.setRowData({}, 'add')
        return
      }
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'edit':
          _this.$refs.DealerRebateEditModal.setRowData(_this.selectedRows[0], 'edit')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              console.log(_this.selectedRows[0])
              const res = await delDealerRebate(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'checkDetail':
          this.$refs.UsedDetailed.setRowData(this.selectedRows[0])
      }
    },
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
::v-deep .ant-table-tbody > .light-row {
  background: #fff !important;
}
::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
}
</style>
